import { History } from 'history';
import { Dispatch } from 'redux';
import headersBuilder, { Method } from '../../utils/request';
import LocalStorageUtils from '../../utils/local-storage';
import Errors from '../../shared/validation/errors';
import SERVICE_API from '../../api';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_RESET_STATE = 'LOGIN_RESET_STATE';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export type AuthAction = {
  type: string;
  user?: any;
};
const loginUserAC = (user: any): AuthAction => ({ type: LOGIN, user });

const logoutUserAC = (): AuthAction => ({ type: LOGOUT });

const loginStartAC = (): AuthAction => ({ type: LOGIN_START });

const loginSuccessAC = (): AuthAction => ({ type: LOGIN_SUCCESS });

const loginResetStateAC = (): AuthAction => ({ type: LOGIN_RESET_STATE });

const loginUserAsync = (
  api: string,
  username: string,
  password: string,
  rememberMe: boolean,
  history: History
) => {
  return (dispatch: Dispatch): void => {
    fetch(
      `${api}/auth/login`,
      headersBuilder(Method.POST, {
        Username: username,
        Password: password,
        rememberMe
      })
    )
      .then((response: Response) => Errors.checkResponseForErrors(response, api))
      .then((user: any) => {
        LocalStorageUtils.putValueToLocalStorage('jwt', user.Token);
        LocalStorageUtils.putValueToLocalStorage(
          'exp',
          (Date.now() + user.ExpiresIn * 1000).toString()
        );
        LocalStorageUtils.putValueToLocalStorage('accountType', user.AccountType);
        LocalStorageUtils.putValueToLocalStorage('user', JSON.stringify(user));
        dispatch(loginUserAC(user));
      })
      .then(() => history.push('/dashboard'))
      .catch((error: Error) => Errors.throwErrorMessage(error));
  };
};

const loginUserThunkAC =
  (payload: { Username: string; Password: string }) =>
  async (dispatch: Dispatch): Promise<any> => {
    try {
      await dispatch(loginStartAC());
      const res = await SERVICE_API.AuthAPI.postLogin(payload);
      await LocalStorageUtils.putValueToLocalStorage('jwt', res?.data.Token);
      await LocalStorageUtils.putValueToLocalStorage(
        'exp',
        (Date.now() + res?.data.ExpiresIn * 1000).toString()
      );
      await LocalStorageUtils.putValueToLocalStorage('accountType', res?.data.AccountType);
      await LocalStorageUtils.putValueToLocalStorage('user', JSON.stringify(res?.data));
      await dispatch(loginUserAC(res?.data));
      await window.location.replace('/dashboard');
      await dispatch(loginSuccessAC());
    } catch ({ response }) {
      dispatch(loginResetStateAC());
      Errors.throwErrorMessageNew(response?.data);
    }
  };

const logoutUserAsync = (api: string, history: History) => {
  return (dispatch: Dispatch): void => {
    fetch(`${api}/auth/logout`, headersBuilder(Method.POST))
      .then(() => {
        dispatch(logoutUserAC());
        LocalStorageUtils.removeValueFromLocalStorage('jwt');
        LocalStorageUtils.removeValueFromLocalStorage('accountType');
        LocalStorageUtils.removeValueFromLocalStorage('user');
      })
      .then(() => history.push('/login'))
      .catch((error: Error) => Errors.throwErrorMessage(error));
  };
};

export { loginUserThunkAC, logoutUserAsync, loginUserAsync, loginResetStateAC };

export default loginUserAsync;
