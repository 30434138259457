import {
  FETCH_INVESTORS,
  FetchInvestorsAction,
  RESET_DATA_INVESTORS
} from '../../actions/investor/fetchInvestors';
import { Investor } from '../../../types/investorTypes';
import { PaginationInfo } from '../../../types/sharedTypes';

export interface InvestorsState {
  investors: Array<Investor>;
  paginationInfo: PaginationInfo;
}

const initialState: InvestorsState = {
  investors: [],
  paginationInfo: new PaginationInfo()
};

const investorsReducer = (
  state: InvestorsState = initialState,
  action: FetchInvestorsAction
): InvestorsState => {
  switch (action.type) {
    case FETCH_INVESTORS:
      return {
        investors: action.investors,
        paginationInfo: action.paginationInfo
      };
    case RESET_DATA_INVESTORS:
      return {
        investors: [],
        paginationInfo: new PaginationInfo()
      };

    // case INVESTORS_SORT_BY_EQUITY_UP:
    //   return {
    //     ...state,
    //     investors: state.investors.sort((a, b) => )
    //   }

    default:
      return state;
  }
};

export default investorsReducer;
