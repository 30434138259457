import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
// import { UpdateInvestor } from '../../../types/investorTypes';
import fetchInvestorDetailsAsync from './fetchInvestorDetails';

function updateInvestor(api: string, investorId: string, request: any) {
  return (dispatch: Dispatch): void => {
    fetch(`${api}/investors/${investorId}`, headersBuilder(Method.POST, request))
    .then((response) => Errors.checkResponseForErrors(response, api))
    // eslint-disable-next-line no-alert
    .then(() => {
      dispatch<any>(fetchInvestorDetailsAsync(api, Number(investorId)));
      toast.success('Investor updated successfully!', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    })
    .catch((error) => Errors.throwErrorMessage(error));
  }
};

export default updateInvestor;
