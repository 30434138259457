/* eslint-disable no-nested-ternary */
import * as React from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {Schema} from 'yup';
import InputUtils from '../../utils/validate-schema';

interface IInputProps {
  state: any;
  valueKey: string | number;
  labelTranslateKey?: string;
  schema: Schema<any>;
  type: 'text' | 'password' | 'number';
  placeholder: string;
  disabled?: boolean;
  className?: string;
  style?: any;
  size?: any;
  inputStyle?: any;
  inline?: boolean;
  acceptZero?: boolean;
  setState(newState: any): void;
}

const SimpleInput: React.FC<IInputProps> = ({
  state,
  valueKey,
  schema,
  type,
  placeholder,
  setState,
  labelTranslateKey,
  disabled,
  className,
  style,
  size,
  inputStyle,
  inline,
  acceptZero
}: IInputProps) => {

  const intl = useIntl();

  let errorMessageBody: JSX.Element = <></>;

  const [modified, setModified] = React.useState<boolean>(false);
  let isInvalid: any = false;

  const wrapper = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!modified) setModified(true);

    const value = (type === 'number' && event.target.value !== '') ? Number(event.target.value) : event.target.value;
    const newState = { ...state, [valueKey]: value };

    setState(newState);
  };
  if (modified) isInvalid = InputUtils.validateSchema<any, any>(schema, state, valueKey);

  if (disabled) {
    errorMessageBody = <div className='empty-error'/>;
  } else if (isInvalid) {
    errorMessageBody = isInvalid.errors?.map((error: string, index: number) => (
      <Form.Control.Feedback key={`${error}-${index}`} type='invalid'>
        {error}
      </Form.Control.Feedback>
    ));
  }

  // const resultClassName = `flex-fill ${className || 'simple-input_min-width'}`;

  let value;

  if (state[valueKey] === 0) {
    value = 0;
  } else if (state[valueKey]) {
    value = state[valueKey];
  } else {
    value = '';
  }

  const inlineInputStyle = {
    borderRight: 'none',
    borderLeft: 'none',
    borderTop: 'none',
    borderRadius: 0,
    fontSize: 11
  } as React.CSSProperties;

  const desktopBody = (
    <>
      <Form.Label>
        <FormattedMessage id={labelTranslateKey}/>
      </Form.Label>
      <Form.Control
        placeholder={intl.formatMessage({id: placeholder})}
        autoComplete='new-password'
        disabled={disabled}
        type={type}
        value={acceptZero ? value : value || ''}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => wrapper(event)}
        isInvalid={disabled ? false : isInvalid}
        size={size || null}
        className={className}
      />
      {isInvalid ? errorMessageBody : <div className='empty-error'/>}
    </>
  );

  const mobileBody = (
    <Row className="mb-3">
      <Col xs={4}>
        <Form.Label className="pt-1" style={{ fontSize: 12, fontWeight: 'bold' }}>
          <FormattedMessage id={labelTranslateKey}/>
        </Form.Label>
      </Col>
      <Col xs={8}>
        <Form.Control
          placeholder={intl.formatMessage({id: placeholder})}
          autoComplete='new-password'
          disabled={disabled}
          type={type}
          value={acceptZero ? value : value || ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => wrapper(event)}
          isInvalid={disabled ? false : isInvalid}
          size="sm"
          style={inline ? inlineInputStyle : {}}
          className={className}
        />
        {isInvalid ? errorMessageBody : <div className='empty-error'/>}
      </Col>
    </Row>
  );

  return (
    labelTranslateKey
    ? (inline ? mobileBody : desktopBody) : (
      <Form.Group style={style || null}>
        <Form.Control
          placeholder={intl.formatMessage({id: placeholder})}
          autoComplete='new-password'
          disabled={disabled}
          type={type}
          value={acceptZero ? value : value || ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => wrapper(event)}
          isInvalid={disabled ? false : isInvalid}
          size={size || null}
          style={inputStyle || null}
          className={className}
        />
        {isInvalid ? errorMessageBody : <div className='empty-error'/>}
      </Form.Group>
    )
  );
};

export default SimpleInput;
