import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { History } from 'history';
import { PrivateRoute } from '../privateRoute';
import useWindowResize from '../../hooks/useWindowSize';
import { mobileWidth } from '../../const/size.const';
import { AccountType } from '../../types/authTypes';
import LocalStorageUtils from '../../utils/local-storage';

// Desktop components
const Login = React.lazy(() => import('../auth/login/Login'));
const InvestorsList = React.lazy(() => import('../administrator/investor/InvestorsList'));
const InvestorDetails = React.lazy(() => import('../administrator/investor/InvestorDetails'));
const MastersList = React.lazy(() => import('../administrator/master/MastersList'));
const MasterDetails = React.lazy(() => import('../administrator/master/MasterDetails'));
const AdminDetails = React.lazy(() => import('../administrator/admin/AdminDetails'));
const Admins = React.lazy(() => import('../administrator/admin/AdminsList'));
const Settings = React.lazy(() => import('../administrator/settings/Settings'));
const CreateUser = React.lazy(() => import('../CreateUser'));
const Journal = React.lazy(() => import('../administrator/journal/Journal'));
const AdminDashboard = React.lazy(() => import('../administrator/admin/AdminDashboard'));
const RequestsList = React.lazy(() => import('../administrator/requests/RequestsList'));
const FeeAccountsList = React.lazy(() => import('../administrator/feeAccounts/FeeAccountsList'));
const OrdersList = React.lazy(() => import('../administrator/orders/OrdersList'));
const ResetPassword = React.lazy(() => import('../resetPassword/ResetPassword'));
const RegisterUser = React.lazy(() => import('../register/RegisterUser'));
const Register = React.lazy(() => import('../auth/register/Register'));

// Mobile components
const LoginMobile = React.lazy(() => import('../mobile/LoginMobile'));
const MasterDetailsMobile = React.lazy(() => import('../mobile/MasterDetailsMobile'));
const MastersListMobile = React.lazy(() => import('../mobile/MastersListMobile'));
const RequestsListMobile = React.lazy(
  () => import('../administrator/requests/mobile/RequestsListMobile')
);
const RequestsFiltersMobile = React.lazy(
  () => import('../administrator/requests/mobile/RequestsFiltersMobile')
);
const InvestorDetailsMobile = React.lazy(() => import('../mobile/InvestorDetailsMobile'));
const ProfileMobile = React.lazy(() => import('../mobile/ProfileMobile'));
const ChangeBalanceCreditMobile = React.lazy(() => import('../mobile/ChangeBalanceCreditMobile'));
const CommissionsMobile = React.lazy(() => import('../mobile/CommissionsMobile'));
const DownloadStatementMobile = React.lazy(() => import('../mobile/DownloadStatementMobile'));
const HistoryMobile = React.lazy(() => import('../mobile/HistoryMobile'));
const OrderMobile = React.lazy(() => import('../mobile/OrderMobile'));
const BalanceOperationMobile = React.lazy(() => import('../mobile/BalanceOperationMobile'));
const BioMobile = React.lazy(() => import('../mobile/BioMobile'));
const SlTpMobile = React.lazy(() => import('../mobile/SlTpMobile'));
const RegisterUserMobile = React.lazy(() => import('../mobile/RegisterUserMobile'));
const RegisterUserSelectMobile = React.lazy(() => import('../mobile/RegisterUserSelectMobile'));
const ConnectedInvestorsMobile = React.lazy(() => import('../mobile/ConnectedInvestors'));
const ConnectedInvestorDetailMobile = React.lazy(() => import('../mobile/ConnectedInvestorDetail'));

// New interface components
const GenUserMastersList = React.lazy(() => import('../generalUser/master/MastersList'));
const GenUserRequestsList = React.lazy(() => import('../generalUser/requests/RequestsList'));
const InvestorDashboard = React.lazy(() => import('../generalUser/investor/InvestorDashboard'));
const MasterDashboard = React.lazy(() => import('../generalUser/master/MasterDashboard'));

interface IAppRoutesProps {
  isAuthenticated: boolean;
  signInFunc: any;
  history: History;
}

const fillRoutes = (
  routes: Array<JSX.Element>,
  isAuthenticated: boolean,
  signInFunc: any,
  history: History,
  showMobileVersion: boolean,
  user: any
) => {
  let redirectAfterLogin: JSX.Element;

  if (user) {
    switch (user.AccountType) {
      case AccountType.Admin:
        redirectAfterLogin = <Redirect to="/dashboard" />;
        break;
      case AccountType.Investor:
        redirectAfterLogin = <Redirect to="/dashboard" />;
        break;
      default:
        break;
    }
  }

  const loginPage = () =>
    isAuthenticated ? (
      redirectAfterLogin
    ) : (
      <div className="text-center">
        {/* {showMobileVersion ? <LoginMobile /> : <AppLoginPage signInFunc={signInFunc} />} */}
        {showMobileVersion ? <LoginMobile /> : <Login />}
      </div>
    );

  if (user) {
    // ADMINISTRATOR (OLD INTERFACE (desktop only))
    if (user.AccountType === AccountType.Admin) {
      routes.push(
        <PrivateRoute
          key="investors-details"
          path="/investors/:id/:name"
          isAuthenticated={isAuthenticated}
          component={<InvestorDetails />}
        />
      );
      routes.push(
        <PrivateRoute
          key="master-details"
          path="/masters/:id/:graphic/:table"
          isAuthenticated={isAuthenticated}
          component={<MasterDetails history={history} />}
        />
      );
      routes.push(
        <PrivateRoute
          key="master-details"
          path="/masters/attach/:id"
          isAuthenticated={isAuthenticated}
          component={<MastersList history={history} />}
        />
      );
      routes.push(
        <PrivateRoute
          key="master-create"
          path="/settings/:type/:subtype?"
          isAuthenticated={isAuthenticated}
          component={<Settings />}
        />
      );
      routes.push(
        <PrivateRoute
          key="create-user"
          path="/create/:user"
          isAuthenticated={isAuthenticated}
          component={<CreateUser history={history} />}
        />
      );
      routes.push(
        <PrivateRoute
          key="edit-admin"
          path="/admins/:id"
          isAuthenticated={isAuthenticated}
          component={<AdminDetails />}
        />
      );
      routes.push(
        <PrivateRoute
          key="investors"
          path="/investors"
          isAuthenticated={isAuthenticated}
          component={<InvestorsList />}
        />
      );
      routes.push(
        showMobileVersion ? (
          <PrivateRoute
            key="masters"
            path="/masters"
            isAuthenticated={isAuthenticated}
            component={<MastersListMobile />}
          />
        ) : (
          <PrivateRoute
            key="masters"
            path="/masters"
            isAuthenticated={isAuthenticated}
            component={<MastersList />}
          />
        )
      );
      routes.push(
        <PrivateRoute
          key="admins"
          path="/admins"
          isAuthenticated={isAuthenticated}
          component={<Admins />}
        />
      );
      routes.push(
        <PrivateRoute
          key="journal"
          path="/journal"
          isAuthenticated={isAuthenticated}
          component={<Journal />}
        />
      );
      routes.push(
        <PrivateRoute
          key="dashboard"
          path="/dashboard"
          isAuthenticated={isAuthenticated}
          component={<AdminDashboard />}
        />
      );
      routes.push(
        showMobileVersion ? (
          <PrivateRoute
            key="requests"
            path="/requests"
            isAuthenticated={isAuthenticated}
            component={<RequestsListMobile />}
          />
        ) : (
          <PrivateRoute
            key="requests"
            path="/requests"
            isAuthenticated={isAuthenticated}
            component={<RequestsList />}
          />
        )
      );
      routes.push(
        <PrivateRoute
          key="fee-accounts"
          path="/fee-accounts"
          isAuthenticated={isAuthenticated}
          component={<FeeAccountsList />}
        />
      );
      routes.push(
        <PrivateRoute
          key="orders"
          path="/orders"
          isAuthenticated={isAuthenticated}
          component={<OrdersList />}
        />
      );
    }

    // INVESTOR (NEW DESKTOP INTERFACE)
    if (user.AccountType === AccountType.Investor && !showMobileVersion) {
      routes.push(
        <PrivateRoute
          key="investors-dashboard"
          path="/dashboard"
          isAuthenticated={isAuthenticated}
          component={<InvestorDashboard />}
        />
      );
      routes.push(
        <PrivateRoute
          key="master-details"
          path="/masters/:id"
          isAuthenticated={isAuthenticated}
          component={<MasterDashboard />}
        />
      );
      routes.push(
        <PrivateRoute
          key="masters"
          path="/masters"
          isAuthenticated={isAuthenticated}
          component={<GenUserMastersList />}
        />
      );
      routes.push(
        <PrivateRoute
          key="requests"
          path="/requests"
          isAuthenticated={isAuthenticated}
          component={<GenUserRequestsList />}
        />
      );
    }
    // MASTER (NEW DESKTOP INTERFACE)
    if (user.AccountType === AccountType.Master && !showMobileVersion) {
      routes.push(
        <PrivateRoute
          key="masters-dashboard"
          path="/dashboard"
          isAuthenticated={isAuthenticated}
          component={<MasterDashboard />}
        />
      );
      routes.push(
        <PrivateRoute
          key="investor-details"
          path="/investors/:id"
          isAuthenticated={isAuthenticated}
          component={<InvestorDashboard />}
        />
      );
      routes.push(
        <PrivateRoute
          key="masters"
          path="/masters"
          isAuthenticated={isAuthenticated}
          component={<GenUserMastersList />}
        />
      );
      routes.push(
        <PrivateRoute
          key="requests"
          path="/requests"
          isAuthenticated={isAuthenticated}
          component={<GenUserRequestsList />}
        />
      );
      routes.push(
        <PrivateRoute
          key="ownFunds"
          path="/ownFunds"
          isAuthenticated={isAuthenticated}
          component={<InvestorDashboard />}
        />
      );
    }

    // INVESTOR or MASTER (MOBILE/TABLET INTERFACE))
    if (showMobileVersion) {
      if (user.AccountType === AccountType.Investor || user.AccountType === AccountType.Master) {
        routes.push(
          <PrivateRoute
            key="order"
            path="/closed-order/:id/:closedOrderId"
            isAuthenticated={isAuthenticated}
            component={<OrderMobile />}
          />
        );
        routes.push(
          <PrivateRoute
            key="sltp"
            path="/edit-sltp/:id/"
            isAuthenticated={isAuthenticated}
            component={<SlTpMobile />}
          />
        );
        routes.push(
          <PrivateRoute
            key="change-balance"
            path="/change-balance/:id"
            isAuthenticated={isAuthenticated}
            component={<ChangeBalanceCreditMobile />}
          />
        );
        routes.push(
          <PrivateRoute
            key="change-credit"
            path="/change-credit/:id"
            isAuthenticated={isAuthenticated}
            component={<ChangeBalanceCreditMobile />}
          />
        );
        routes.push(
          <PrivateRoute
            key="download-statement"
            path="/download-statement/:id"
            isAuthenticated={isAuthenticated}
            component={<DownloadStatementMobile />}
          />
        );
        routes.push(
          <PrivateRoute
            key="commissions"
            path="/commissions/:id"
            isAuthenticated={isAuthenticated}
            component={<CommissionsMobile />}
          />
        );
        routes.push(
          <PrivateRoute
            key="connected-investors"
            path="/connected-investors/:id"
            isAuthenticated={isAuthenticated}
            component={<ConnectedInvestorsMobile />}
          />
        );
        routes.push(
          <PrivateRoute
            key="connected-investors"
            path="/investors/:investorId"
            isAuthenticated={isAuthenticated}
            component={<ConnectedInvestorDetailMobile />}
          />
        );

        if (
          user.AccountType === AccountType.Investor &&
          user.AccountOptions.InvestorMmDetails === 'Show'
        ) {
          routes.push(
            <PrivateRoute
              key="master-details"
              path="/masters/:id"
              isAuthenticated={isAuthenticated}
              component={<MasterDetailsMobile />}
            />
          );
        }

        routes.push(
          <PrivateRoute
            key="history"
            path="/history/:id"
            isAuthenticated={isAuthenticated}
            component={<HistoryMobile />}
          />
        );
        // routes.push(<PrivateRoute key="history" path="/:userType/:id" isAuthenticated={isAuthenticated} component={<HistoryMobile />} />);
        routes.push(
          <PrivateRoute
            key="bio"
            path="/bio/:id"
            isAuthenticated={isAuthenticated}
            component={<BioMobile />}
          />
        );
        routes.push(
          <PrivateRoute
            key="balance-operation"
            path="/balance-operation/:id/:balanceOperationId"
            isAuthenticated={isAuthenticated}
            component={<BalanceOperationMobile />}
          />
        );
        routes.push(
          <PrivateRoute
            key="requests-filters"
            path="/requests/filters"
            isAuthenticated={isAuthenticated}
            component={<RequestsFiltersMobile />}
          />
        );
        routes.push(
          <PrivateRoute
            key="masters"
            path="/masters"
            isAuthenticated={isAuthenticated}
            component={<MastersListMobile />}
          />
        );
        routes.push(
          <PrivateRoute
            key="requests"
            path="/requests"
            isAuthenticated={isAuthenticated}
            component={<RequestsListMobile />}
          />
        );
        routes.push(
          <PrivateRoute
            key="dashboard"
            path="/dashboard"
            isAuthenticated={isAuthenticated}
            component={
              user.AccountType === AccountType.Investor ? (
                <InvestorDetailsMobile investorId={user.Id} />
              ) : (
                <MasterDetailsMobile masterId={user.Id} />
              )
            }
          />
        );
        routes.push(
          <PrivateRoute
            key="profile"
            path="/profile"
            isAuthenticated={isAuthenticated}
            component={<ProfileMobile user={user} />}
          />
        );
      }
    }
  }

  if (showMobileVersion) {
    routes.push(
      <Route
        key="register-user"
        path="/register-user/:userType/"
        render={() => <RegisterUserMobile isMobile />}
      />
    );
    routes.push(
      <Route
        key="register-user"
        path="/register-user/"
        render={() => <RegisterUserSelectMobile />}
      />
    );
  } else {
    routes.push(<Route key="register-user" path="/register-user" render={() => <Register />} />);
  }
  routes.push(
    <Route key="reset-password" path="/reset-password" render={() => <ResetPassword />} />
  );
  routes.push(<Route key="admin" path="/admin" render={loginPage} />);
  routes.push(<Route key="login" path="/" render={loginPage} />);
};

const AppRoutes: React.FC<IAppRoutesProps> = ({
  isAuthenticated,
  signInFunc,
  history
}: IAppRoutesProps): JSX.Element => {
  const [width, height] = useWindowResize();

  const [showMobileVersion, setShowMobileVersion] = React.useState(width < mobileWidth);
  const userJSON = LocalStorageUtils.getValueFromLocalStorage('user');
  let user: any;

  if (typeof userJSON === 'string') {
    user = JSON.parse(userJSON);
  }

  React.useEffect(() => {
    setShowMobileVersion(width < mobileWidth);
  }, [width, height]);

  const routes: Array<JSX.Element> = [];

  fillRoutes(routes, isAuthenticated, signInFunc, history, showMobileVersion, user);

  return (
    <Suspense fallback="Loading...">
      <Switch>{routes}</Switch>
    </Suspense>
  );
};

export default AppRoutes;
