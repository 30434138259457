import { Dispatch } from 'redux';
import headersBuilder, { Method } from '../../../utils/request';
import setLoadingStatus from '../setLoadingStatus';
import Errors from '../../../shared/validation/errors';
import { Master } from '../../../types/masterTypes';

export const FETCH_MASTER_DETAILS = 'FETCH_MASTER_DETAILS ';

export type FetchDetailsAction = {
  type: string;
  masterDetails: Master;
}

export const fetchMasterDetails = (master: Master): FetchDetailsAction => {
  return {
    type: FETCH_MASTER_DETAILS,
    masterDetails: master
  }
};

function fetchMasterDetailsAsync(api: string, masterId: number, notAdmin?: boolean) {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));

    fetch(`${api}/masters/${masterId}?returnAccountOptionsValue=${!!notAdmin}`, headersBuilder(Method.GET))
    .then((response: Response) => Errors.checkResponseForErrors(response, api))
    .then((master: any) => dispatch(fetchMasterDetails(master)))
    .catch((error: Error) => Errors.throwErrorMessage(error))
    .finally(() => dispatch(setLoadingStatus(false)));
  }
}

export default fetchMasterDetailsAsync;
