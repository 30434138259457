import { Dispatch } from 'redux';
import LocalStorageUtils from '../../../utils/local-storage';
import Errors from '../../../shared/validation/errors';
import setLoadingStatus from '../setLoadingStatus';
import headersBuilder, { Method } from '../../../utils/request';
import { Investor } from '../../../types/investorTypes';
import { PaginationInfo } from '../../../types/sharedTypes';

export const FETCH_INVESTORS = 'FETCH_INVESTORS';
export const RESET_DATA_INVESTORS = 'RESET_DATA_INVESTORS';

export type FetchInvestorsAction = {
  type: string;
  investors: Array<Investor>;
  paginationInfo: PaginationInfo;
};

export const fetchInvestors = (
  investors: Array<Investor>,
  paginationInfo: PaginationInfo
): FetchInvestorsAction => {
  return {
    type: FETCH_INVESTORS,
    investors,
    paginationInfo
  };
};

export const resetDataInvestorsAC = () => ({ type: RESET_DATA_INVESTORS });

function fetchInvestorsAsync(
  api: string,
  linkToken?: string,
  filter?: boolean,
  mmId?: number,
  page?: number,
  limit?: number,
  query?: string,
  sortField?: string,
  direction?: string
): any {
  let queryString = `?page=${page}`;

  if (limit) queryString += `&limit=${limit}`;
  if (filter) queryString += '&filter=disabled';
  if (query) queryString += `&query=${query}`;
  if (sortField) queryString += `&sortField=${sortField}`;
  if (direction) queryString += `&direction=${direction}`;
  if (mmId) queryString += `&masterId=${mmId}`;
  if (linkToken) queryString += `&linkToken=${linkToken}`;

  if (!LocalStorageUtils.localStorageKeyExists('investors')) {
    LocalStorageUtils.putValuesToLocalStorage('investors', [
      'id=true',
      'mtLogin=true',
      'name=true',
      'currency=true',
      'balance=true',
      'credit=true',
      'equity=true',
      'profit=true',
      'masterId=true',
      'masterName=true'
    ]);
  }

  return (dispatch: Dispatch) => {
    dispatch(setLoadingStatus(true));

    fetch(`${api}/investors${queryString}`, headersBuilder(Method.GET))
      .then((response: Response) => Errors.checkResponseForErrors(response, api))
      .then((data: any) => dispatch(fetchInvestors(data.Data, data.PaginationInfo)))
      .catch((error: Error) => Errors.throwErrorMessage(error))
      .finally(() => dispatch(setLoadingStatus(false)));
  };
}

export default fetchInvestorsAsync;
