import { SET_LOADING_STATUS, SetLoadingStatusAction } from '../actions/setLoadingStatus';

export interface LoadingStatusState {
  isLoading: boolean;
}

const initialState: LoadingStatusState = {
  isLoading: false,
};

function loadingStatusReducer(state: LoadingStatusState = initialState, action: SetLoadingStatusAction): LoadingStatusState {
  switch (action.type) {
    case SET_LOADING_STATUS: {
      return {
        isLoading: action.status
      };
    }
    default: {
      return state;
    }
  }
}

export default loadingStatusReducer;
