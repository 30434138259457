import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createBrowserHistory } from 'history';
import { toast } from 'react-toastify';
import { AxiosError, AxiosResponse } from 'axios';
import store from '../../redux/store';
import { logoutUserAsync } from '../../redux/actions/auth';

const history = createBrowserHistory();

const bottomRightToastPosition = { position: toast.POSITION.BOTTOM_RIGHT };

interface IError {
  Message: string;
  Code: string;
  Type: string;
}
// TODO переделать все на новое
const throwErrorMessageNew = (error: IError): void => {
  toast.error(<FormattedMessage id={error.Message} />, bottomRightToastPosition);
};

function throwErrorMessage(e: Error): void {
  toast.error(<FormattedMessage id={e.message} />, bottomRightToastPosition);
}

function throwSuccessMessage(message: string, values?: any): void {
  if (values) {
    toast.success(<FormattedMessage id={message} values={values} />, bottomRightToastPosition);
  } else {
    toast.success(<FormattedMessage id={message} />, bottomRightToastPosition);
  }
}

function checkResponseForErrors(response: Response, api: string, isText = false): Promise<unknown> {
  switch (response?.status) {
    // Bad Request
    case 400: {
      // throw Error(`${response.status}: ${response.text()}`);
      return response.text().then((text) => {
        throw new Error(`${JSON.parse(text).Code}: ${JSON.parse(text).Message}`);
      });
    }
    // Unauthorized
    case 401: {
      store.dispatch(logoutUserAsync(api, history));
      return response.text().then((text) => {
        throw new Error(`${JSON.parse(text).Code}: ${JSON.parse(text).Message}`);
      });
    }
    // Forbidden
    case 403: {
      return response.text().then((text) => {
        throw new Error(`${JSON.parse(text).Code}: ${JSON.parse(text).Message}`);
      });
    }
    // Not Found
    case 404: {
      return response.text().then((text) => {
        throw new Error(`${JSON.parse(text).Code}: ${JSON.parse(text).Message}`);
      });
    }
    // Internal Server Error
    case 500: {
      return response.text().then((text) => {
        throw new Error(`${JSON.parse(text).Code}: ${JSON.parse(text).Message}`);
      });
    }
    default: {
      if (isText) {
        return response.text().catch(() => throwErrorMessage(Error('errors.text')));
      }
      return response.json().catch(() => throwErrorMessage(Error('errors.json')));
    }
  }
}

// TODO новая валидация для axios
function newCheckResponseForErrors(response: AxiosResponse<any> | undefined, api: string): void {
  switch (response?.status) {
    // Unauthorized
    case 401:
      store.dispatch(logoutUserAsync(api, history));
      break;

    default: {
      throw new Error(`${JSON.parse(response?.data?.Code)}: ${response?.data?.Message}`);
    }
  }
}

const Errors = {
  throwErrorMessage,
  throwSuccessMessage,
  checkResponseForErrors,
  newCheckResponseForErrors,
  throwErrorMessageNew
};

export default Errors;
