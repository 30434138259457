import { ConfigFlags } from '../../types/sharedTypes';
import { FETCH_CONFIG, FetchConfigAction, FETCH_LANG_JSON, FetchLanguageJSONAction } from '../actions/shared/fetchConfig';

export interface ConfigState {
  api: string;
  customColors: Record<string, string>;
  customColorsGeneralUser: Record<string, string>;
  languages: Record<string, string>;
  loginPageDisclaimer: string;
  redirectRegistrationURL: string;
  welcomeMessage: string;
  langFile: Record<string, string>;
  flags: ConfigFlags;
}

const initialState: ConfigState = {
  api: '',
  customColors: {},
  customColorsGeneralUser: {},
  languages: {},
  loginPageDisclaimer: '',
  redirectRegistrationURL: '',
  welcomeMessage: '',
  langFile: {},
  flags: {} as ConfigFlags
};

const configReducer = (state: ConfigState = initialState, action: FetchConfigAction & FetchLanguageJSONAction): ConfigState => {
  switch (action.type) {
    case FETCH_CONFIG:
      return {
        ...state,
        api: action.config.api,
        customColors: action.config.customColors,
        customColorsGeneralUser: action.config.customColorsGeneralUser,
        languages: action.config.languages,
        loginPageDisclaimer: action.config.loginPageDisclaimer,
        redirectRegistrationURL: action.config.redirectRegistrationURL,
        welcomeMessage: action.config.welcomeMessage,
        flags: action.config.flags
      };
    case FETCH_LANG_JSON:
      return {
        ...state,
        langFile: action.langFile
      }
    default:
      return state;
  }
}

export default configReducer;
