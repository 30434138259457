/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useSelector } from 'react-redux';
import Icons from '../../icons/icons';
import { PAMMState } from '../../redux/reducers/rootReducer';

const LanguagesMenuMobile: React.FC<any> = ({
  menuHeight = 0,
  onSwipe,
  onCancel,
  onLangChange
}): JSX.Element => {
  const languages: Record<string, string> = useSelector((state: PAMMState) => state.configReducer.languages);

  const langLinks = Object.keys(languages).map((lang: string, index: number) => (
    <div key={index} onClick={() => onLangChange(lang)}>
      <div className="row mb-3 align-items-center">
        <span className="mb-1">
          {languages[lang]}
        </span>
      </div>

      {index === Object.keys(languages).length
      ? <hr />
      : null}
    </div>
  ));

  const style = {
    width: '100%',
    height: menuHeight,
    position: 'fixed',
    zIndex: 9999,
    bottom: '-5rem',
    backgroundColor: 'white',
    transition: '0.5s',
    padding: '1.5rem',
    fontSize: '1.3rem'
  } as React.CSSProperties; 

  return (
    <div
      id="languages-menu"
      style={style}
      onPointerMove={(event) => onSwipe(event)}
      onPointerCancel={() => onCancel('languagesMenu')}
    >
      {langLinks}
    </div>
  );
};

export default LanguagesMenuMobile;