import LocalStorageUtils from './local-storage';

const isTokenExpired = (): boolean => {
  if (Number(LocalStorageUtils.getValueFromLocalStorage('exp')) < Date.now()) {
    return true;
  }

  return false;
}

export default isTokenExpired;