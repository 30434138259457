import * as React from 'react';
import { useEffect } from 'react';
import { Redirect, Route } from 'react-router';
import { useDispatch } from 'react-redux';
import { getInfoMastersThunkAC } from '../../redux/actions/master/fetchMasters';

export const PrivateRoute = ({ component, ...rest }: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInfoMastersThunkAC());
  }, []);

  const routeComponent = () =>
    rest.isAuthenticated ? component : <Redirect to={{ pathname: '/login' }} />;
  return <Route {...rest} render={routeComponent} />;
};
