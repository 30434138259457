import { Dispatch } from 'redux';
import { History } from 'history';
import { toast } from 'react-toastify';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
import { Admin, EditAdminRequest } from '../../../types/adminTypes';

export const EDIT_ADMIN = 'EDIT_ADMIN';

export type EditAdminAction = {
  type: string;
  editedAdmin: Admin;
};

const editAdmin = (editedAdmin: Admin): EditAdminAction => {
  return {
    type: EDIT_ADMIN,
    editedAdmin
  };
};

const editAdminAsync = (api: string, id: number, request: EditAdminRequest, history: History) => {
  return (dispatch: Dispatch): void => {
    fetch(`${api}/admins/${id}`, headersBuilder(Method.POST, request))
    .then((response: Response) => Errors.checkResponseForErrors(response, api))
    .then((editedAdmin: any) => {
      dispatch(editAdmin(editedAdmin));
      toast.success(`Admin ID ${id} edited successfully!`, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      history.push('/admins');
    })
    .catch((error: Error) => Errors.throwErrorMessage(error));
  };
};

export default editAdminAsync;
