import { Dispatch } from 'redux';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
import { GraphicsPeriodTypes } from '../../../types/masterTypes';
import { Summary } from '../../../types/sharedTypes';
import { getFromDateForRequest, getToDateForRequest } from '../../../utils/date-time';

export const FETCH_VOLUME = 'FETCH_VOLUME';

export type FetchVolumeAction = {
  type: string;
  volumeFortnight: Array<Summary>;
};

const fetchVolume = (volumeFortnight: Array<Summary>): FetchVolumeAction => {
  return {
    type: FETCH_VOLUME,
    volumeFortnight
  };
};

const fetchVolumeAsync = (api: string, id: number, period: GraphicsPeriodTypes, dateFrom: Date, dateTo: Date) => {
  let query = `${id}?period=${period}`;

  const dateTimeDateFrom = getFromDateForRequest(dateFrom);
  const dateTimeDateTo = getToDateForRequest(dateTo);

  if (dateFrom) query += `&from=${dateTimeDateFrom}`;
  if (dateTo) query += `&to=${dateTimeDateTo}`;

  return (dispatch: Dispatch): void => {
    fetch(`${api}/summary/volumes/${query}`, headersBuilder(Method.GET))
    .then((response: Response) => Errors.checkResponseForErrors(response, api))
    .then((data: any) => {
      dispatch(fetchVolume(data));
    })
    .catch((error: Error) => Errors.throwErrorMessage(error));
  }
};

export default fetchVolumeAsync;
