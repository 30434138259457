import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Navbar, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Icons from '../../icons/icons';
import LocalStorageUtils from '../../utils/local-storage';

const BottomBarMobile: React.FC<any> = ({ user, location }): JSX.Element => {
  const showRequestTab = LocalStorageUtils.getValueFromLocalStorage('showRequestTab');
  const currentAddress = location.pathname;

  const shouldDashboardButtonLightUp = (): boolean => {
    return currentAddress.includes('dashboard') ||
      currentAddress.includes('commissions') ||
      currentAddress.includes('closed-order') ||
      currentAddress.includes('history') ||
      currentAddress.includes('change-balance') ||
      currentAddress.includes('change-credit') ||
      currentAddress.includes('balance-operation');
  };
  
  const onlyTwoButtonsActive = user.AccountOptions.MasterList === 'Hide' && showRequestTab === 'false';

  const buttonStyle = {
    display: 'flex',
    width: '2.5rem',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '.3rem',
    marginTop: '.3rem',
  } as React.CSSProperties;

  const activeButtonStyle = {
    ...buttonStyle,
    color: '#5DADE2',
  } as React.CSSProperties;
  
  const textStyle = {
    color: 'black',
    fontSize: 12,
  } as React.CSSProperties;

  const activeTextStyle = {
    ...textStyle,
    color: '#5DADE2',
    textDecoration: 'none',
    outline: 'none',
  } as React.CSSProperties;

  return (
    <Navbar
      fixed="bottom"
      className={`flex ${onlyTwoButtonsActive ? 'justify-content-around' : 'justify-content-between'}`}
      style={{ paddingBottom: 22, backgroundColor: '#EFEFEF' }}
    >
      <Link to="/dashboard">
        <Col>
          <div
            style={shouldDashboardButtonLightUp() ? activeButtonStyle : buttonStyle}
            className="mx-auto"
          >
            {Icons.ChartAreaIcon(shouldDashboardButtonLightUp() ? '#5dade2' : '#343a40', '1x')}
          </div>
          <div style={shouldDashboardButtonLightUp() ? activeTextStyle : textStyle} className="text-center">
            <FormattedMessage id="bottomBar.dashboard" />
          </div>
        </Col>
      </Link>
      {/* <Link to="/investors">
        <Col>
          <div style={buttonStyle} className="mx-auto">
            {Icons.UserIcon('#343a40', '1x')}
          </div>
          <div style={textStyle} className="text-center">
            <FormattedMessage id="bottomBar.investors" />
          </div>
        </Col>
      </Link> */}
      {user.AccountOptions.MasterList === 'Show'
      ? (
        <Link to="/masters">
          <Col>
            <div
              style={currentAddress.includes('/masters') || currentAddress.includes('/bio') ? activeButtonStyle : buttonStyle}
              className="mx-auto"
            >
              {Icons.UsersIcon(currentAddress.includes('/masters') || currentAddress.includes('/bio') ? '#5dade2' : '#343a40', '1x')}
            </div>
            <div style={currentAddress === '/masters' ? activeTextStyle : textStyle} className="text-center">
              <FormattedMessage id="bottomBar.masters" />
            </div>
          </Col>
        </Link>
      ) : null}
      {showRequestTab !== 'false'
      ? (
        <Link to={{ pathname: '/requests', key: Math.random().toString()}}>
          <Col>
            <div
              style={currentAddress.includes('/requests') ? activeButtonStyle : buttonStyle}
              className="mx-auto"
            >
              {Icons.ExchangeIcon(currentAddress.includes('/requests') ? '#5dade2' : '#343a40', '1x')}
            </div>
            <div style={currentAddress === '/requests' ? activeTextStyle : textStyle} className="text-center">
              <FormattedMessage id="bottomBar.requests" />
            </div>
          </Col>
        </Link>
      ) : null}
      <Link to="/profile">
        <Col>
          <div
            style={currentAddress === '/profile' ? activeButtonStyle : buttonStyle}
            className="mx-auto"
          >
            {Icons.CogIcon(currentAddress === '/profile' ? '#5dade2' : '#343a40', '1x')}
          </div>
          <div style={currentAddress === '/profile' ? activeTextStyle : textStyle} className="text-center">
            <FormattedMessage id="bottomBar.profile" />
          </div>
        </Col>
      </Link>
    </Navbar>
  );
};

export default withRouter(BottomBarMobile);