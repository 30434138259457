import { Dispatch } from 'redux';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
import { Admin } from '../../../types/adminTypes';
import setLoadingStatus from '../setLoadingStatus';

export const FETCH_ADMINS = 'FETCH_ADMINS';

export type FetchAdminsAction = {
  type: string;
  admins: Array<Admin>;
};

const fetchAdmins = (admins: Array<Admin>): FetchAdminsAction => {
  return {
    type: FETCH_ADMINS,
    admins
  };
};

const fetchAdminsAsync = (api: string) => {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));

    fetch(`${api}/admins`, headersBuilder(Method.GET))
    .then((response: Response) => Errors.checkResponseForErrors(response, api))
    .then((admins: any) => dispatch(fetchAdmins(admins)))
    .catch((error: Error) => Errors.throwErrorMessage(error))
    .finally(() => dispatch(setLoadingStatus(false)));
  }
};

export default fetchAdminsAsync;