import { FETCH_MASTERS, FetchMastersAction } from '../../actions/master/fetchMasters';
import { Master } from '../../../types/masterTypes';
import { PaginationInfo } from '../../../types/sharedTypes';

export interface MastersState {
  masters: Array<Master>;
  paginationInfo: PaginationInfo;
}

const initialState: MastersState = {
  masters: [],
  paginationInfo: new PaginationInfo(),
};

const mastersReducer = (state: MastersState = initialState, action: FetchMastersAction): MastersState => {
  switch (action.type) {
    case FETCH_MASTERS:
      return {
        masters: action.masters,
        paginationInfo: action.paginationInfo
      };
    default:
      return state;
  }
}

export default mastersReducer;
