import { FETCH_PROFIT, FETCH_PROFIT_PER_PERIOD, FETCH_ARRAY_OF_PROFITS, FetchProfitAction, FetchArrayOfProfitsAction } from '../../actions/shared/fetchProfit';
import { Summary } from '../../../types/sharedTypes';
import { MasterProfit } from '../../../types/masterTypes';

export interface ProfitState {
  profit: Array<Summary>;
  profitPerPeriod: Array<Summary>;
  arrayOfProfits: Array<MasterProfit>;
}

const initialState: ProfitState = {
  profit: [],
  profitPerPeriod: [],
  arrayOfProfits: []
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const profitReducer = (state: ProfitState = initialState, action: FetchProfitAction & FetchArrayOfProfitsAction): ProfitState => {
  switch (action.type) {
    case FETCH_PROFIT:
      return {
        ...state,
        profit: action.profitFortnight
      };
    case FETCH_PROFIT_PER_PERIOD:
      return {
        ...state,
        profitPerPeriod: action.profitFortnight
      };
    case FETCH_ARRAY_OF_PROFITS:
      // eslint-disable-next-line no-case-declarations
      const arrayOfProfitsCopy = state.arrayOfProfits.map((x) => x);
      arrayOfProfitsCopy.push({
        masterId: action.masterId,
        profitFortnight: action.profitFortnight
      });

      return {
        ...state,
        arrayOfProfits: arrayOfProfitsCopy
      }
    default:
      return state;
  }
}

export default profitReducer;