import React from 'react';
import { Button, Dropdown, DropdownButton, Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { shadowBox } from '../../styles/styles';
import T4BLogo from '../../logos/logo-dark.png';
import { PAMMState } from '../../redux/reducers/rootReducer';
import fetchRequestsCountAsync from '../../redux/actions/requests/fetchRequestsCount';
import LocalStorageUtils from '../../utils/local-storage';
import { AccountType, User } from '../../types/authTypes';
import InvestorEditModal from '../administrator/investor/modals/InvestorEditModal';
import { Investor } from '../../types/investorTypes';
import { Master } from '../../types/masterTypes';
import MasterEditModal from '../administrator/master/modals/MasterEditModal';
import Icons from '../../icons/icons';

const GenUserNavbar: React.FC<any> = ({
  location,
  signOutFunc,
  currentLang,
  languages,
  onLangChange
}): JSX.Element => {
  const dispatch = useDispatch();

  const api: string = useSelector((state: PAMMState) => state.configReducer.api);
  const customColors: Record<string, string> = useSelector(
    (state: PAMMState) => state.configReducer.customColorsGeneralUser
  );
  const requestsCount: number = useSelector((state: PAMMState) => state.requestsReducer.count);
  const investor: Investor = useSelector((state: PAMMState) => state.investorReducer.investor);
  const master: Master = useSelector((state: PAMMState) => state.masterReducer.master);
  const userJSON = LocalStorageUtils.getValueFromLocalStorage('user');
  const [user, setUser] = React.useState<User>({} as User);
  const [showEditInvestorModal, setShowEditInvestorModal] = React.useState(false);
  const [showEditMasterModal, setShowEditMasterModal] = React.useState(false);

  React.useEffect(() => {
    if (typeof userJSON === 'string') {
      setUser(JSON.parse(userJSON));
    }
  }, [userJSON]);

  React.useEffect(() => {
    dispatch(fetchRequestsCountAsync(api));
  }, [dispatch, api]);

  const btnStyle = {
    background: customColors.light,
    border: 'none',
    color: customColors.additionalText,
    padding: 0,
    marginLeft: '1rem',
    marginRight: '1rem',
    borderRadius: 0,
    fontSize: 16
  } as React.CSSProperties;

  const btnStyleActive = {
    background: customColors.light,
    border: 'none',
    padding: 0,
    marginLeft: '1rem',
    marginRight: '1rem',
    color: customColors.dark,
    borderRadius: 0,
    fontSize: 16
  } as React.CSSProperties;

  const linkStyleActive = {
    borderBottom: `2px solid ${customColors.dark}`
  } as React.CSSProperties;

  const logOutBtn = {
    border: 'none',
    backgroundColor: 'white',
    padding: 0
  } as React.CSSProperties;

  const langDropdownStyle = {
    backgroundColor: 'white',
    border: 'none',
    color: 'gray',
    fontSize: 14
  } as React.CSSProperties;

  const toggleModalVisibility = (): void => {
    if (user?.AccountType === 'investor') {
      setShowEditInvestorModal(!showEditInvestorModal);
    }
    if (user?.AccountType === 'master') {
      setShowEditMasterModal(!showEditMasterModal);
    }
  };

  const languagesItems = Object.keys(languages)
    .filter((lang: string) => lang !== currentLang)
    .map((lang: string, index: number) => {
      return (
        <Dropdown.Item key={`${lang}-${index}`} onClick={() => onLangChange(lang)}>
          {languages[lang]}
        </Dropdown.Item>
      );
    });

  return (
    <>
      <Navbar
        style={{ ...shadowBox, height: 66, zIndex: 99 }}
        className="d-flex align-items-stretch"
      >
        <div style={{ maxWidth: 260, marginLeft: '1rem', marginRight: '2rem', overflow: 'hidden' }} className='d-flex align-items-center'>
          <Link to="/dashboard">
            <img
              src={T4BLogo}
              alt="Tools for Brokers logo"
            />
          </Link>
        </div>


        <Link
          to="/dashboard"
          className="d-flex align-items-center"
          style={location.pathname === '/dashboard' ? linkStyleActive : {}}
        >
          <Button style={location.pathname === '/dashboard' ? btnStyleActive : btnStyle}>
            <FormattedMessage id="navbar.dashboard" />
          </Button>
        </Link>

        {user?.AccountType === AccountType.Master &&
        user?.AccountOptions?.ShowMmOwnFunds === 'Show' ? (
          <Link
            to="/ownFunds"
            className="d-flex align-items-center"
            style={location.pathname === '/ownFunds' ? linkStyleActive : {}}
          >
            <Button style={location.pathname === '/ownFunds' ? btnStyleActive : btnStyle}>
              <FormattedMessage id="navbar.ownFunds" />
            </Button>
          </Link>
        ) : null}

        {user?.AccountType === AccountType.Investor &&
        user?.AccountOptions?.MasterList === 'Show' ? (
          <Link
            to="/masters"
            className="d-flex align-items-center"
            style={location.pathname.includes('masters') ? linkStyleActive : {}}
          >
            <Button style={location.pathname.includes('masters') ? btnStyleActive : btnStyle}>
              <FormattedMessage id="navbar.masters" />
            </Button>
          </Link>
        ) : null}

        <Link
          to="/requests"
          className="d-flex align-items-center"
          style={location.pathname === '/requests' ? linkStyleActive : {}}
        >
          <Button style={location.pathname === '/requests' ? btnStyleActive : btnStyle}>
            <FormattedMessage id="navbar.requests" /> ({requestsCount})
          </Button>
        </Link>

        <div className="d-flex ml-auto mr-3 align-items-center">
          <Dropdown>
            <Dropdown.Toggle
              id="languages-dropdown"
              className="d-flex align-items-center"
              style={langDropdownStyle}
            >
              <span className="mr-2">{languages[currentLang] || languages.en}</span>
              <span className="pb-1">{Icons.DropdownIcon('gray', '1x')}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu alignRight>{languagesItems}</Dropdown.Menu>
          </Dropdown>
          <Button size="sm" variant="link" onClick={toggleModalVisibility}>
            <span className="mr-3" style={{ color: customColors.active, fontSize: 20 }}>
              {user?.Name}
            </span>
          </Button>
          <Button style={logOutBtn} onClick={signOutFunc}>
            <FontAwesomeIcon size="lg" color={customColors.additionalText} icon={faSignOutAlt} />
          </Button>
        </div>
      </Navbar>
      <InvestorEditModal
        investor={investor}
        show={showEditInvestorModal}
        toggleVisibility={toggleModalVisibility}
      />
      <MasterEditModal
        master={master}
        show={showEditMasterModal}
        toggleVisibility={toggleModalVisibility}
      />
    </>
  );
};

export default withRouter(GenUserNavbar);
