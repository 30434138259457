import { Dispatch } from 'redux';
import headersBuilder, { Method } from '../../../utils/request';
import setLoadingStatus from '../setLoadingStatus';
import Errors from '../../../shared/validation/errors';
import { Investor } from '../../../types/investorTypes';

export const FETCH_INVESTOR_DETAILS = 'FETCH_INVESTORS_DETAILS ';

export type FetchInvestorDetailsAction = {
  type: string;
  investorDetails: Investor;
};

export const fetchInvestorDetails = (investor: Investor): FetchInvestorDetailsAction => {
  return {
    type: FETCH_INVESTOR_DETAILS,
    investorDetails: investor
  };
};

function fetchInvestorDetailsAsync(api: string, investorId: number, admin?: boolean) {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));

    fetch(
      `${api}/investors/${investorId}?returnAccountOptionsValue=${!admin}`,
      headersBuilder(Method.GET)
    )
      .then((response: Response) => Errors.checkResponseForErrors(response, api))
      .then((investor: any) => dispatch(fetchInvestorDetails(investor)))
      .catch((error: Error) => Errors.throwErrorMessage(error))
      .finally(() => dispatch(setLoadingStatus(false)));
  };
}

export default fetchInvestorDetailsAsync;
