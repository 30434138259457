/* eslint-disable @typescript-eslint/ban-types */
import axios, { AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import LocalStorageUtils from '../utils/local-storage';
import Errors from '../shared/validation/errors';

class RestAPI {
  url: string;

  tokenName: string;

  constructor(tokenName = 'jwt') {
    this.url = LocalStorageUtils.getValueFromLocalStorage('apiUrl') || '';
    this.tokenName = tokenName;
  }

  handleSuccess = (response: AxiosResponse): AxiosResponse => response;

  handleError = (error: AxiosError): Promise<any> | void => {
    // Errors.newCheckResponseForErrors(error?.response, this.url);
    return Promise.reject(error);
  };

  create = (headers?: any): AxiosInstance => {
    const cancel = axios.CancelToken.source();
    const token = localStorage.getItem(this.tokenName);
    const headerAuth = token && { Authorization: `Bearer ${token}` };
    const service = axios.create({
      headers: {
        ...headers,
        ...headerAuth
      },
      cancelToken: cancel.token
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    return service;
  };

  get = (path = '', params?: object, headers?: any): Promise<any> => {
    const service = this.create(headers);
    return service.request({
      method: 'GET',
      url: `${this.url}${path}`,
      params
    });
  };

  post = (path = '', data: object = {}, headers?: any): Promise<any> => {
    const service = this.create(headers);
    return service.request({
      method: 'POST',
      url: `${this.url}${path}`,
      data
    });
  };

  put = (path = '', data: object = {}, headers?: any): Promise<any> => {
    const service = this.create(headers);
    return service.request({
      method: 'PUT',
      url: `${this.url}${path}`,
      data
    });
  };

  delete = (path = '', params: object = {}, headers?: any): Promise<any> => {
    const service = this.create(headers);
    return service.request({
      method: 'DELETE',
      url: `${this.url}${path}`,
      params
    });
  };
}

export default new RestAPI();
