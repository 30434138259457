// const getValuesFromDateTimeString = (dateTimeString: string): Array<string> => {
//   // String with the format 2020-04-08T08:59:20+02:00
//   const date: Array<string> = dateTimeString.substring(0, 10).split('-');
//   const time: Array<string> = dateTimeString.substring(11, 16).split(':');

//   const year: string = date[0];
//   const month: string = date[1];
//   const day: string = date[2];

//   const hour: string = time[0];
//   const minute: string = time[1];

//   return [day, month, year, hour, minute];
// };

class PAMMDateTime {
  private date: Array<string>;
  private time: Array<string>;

  public year: string;
  public month: string;
  public day: string;

  public hours: string;
  public minutes: string;

  constructor(dateTimeString: string) {
    this.date = dateTimeString?.substring(0, 10).split('-');
    this.time = dateTimeString?.substring(11, 16).split(':');

    [this.year, this.month, this.day] = this.date;
    [this.hours, this.minutes] = this.time;
  }

  public getDateTimeEN(): string {
    return `${this.getDateEN()} - ${this.getTimeEN()}`;
  }

  public getDateTimeRU(): string {
    return `${this.getDateRU()} - ${this.getTimeRU()}`;
  }

  public getDateEN(): string {
    return `${this.month}/${this.day}/${this.year}`;
  }

  public getDateRU(): string {
    return `${this.day}/${this.month}/${this.year}`;
  }

  public getTimeEN(): string {
    return `${(+this.hours > 12) ? `0${+this.hours - 11}` : this.hours}:${this.minutes} ${+this.hours >= 12 ? 'PM' : 'AM'}`;
  }

  public getTimeRU(): string {
    return `${this.hours}:${this.minutes}`;
  }

  public getYearMonthDayTime(): string {
    return `${this.year}/${this.month}/${this.day} - ${this.hours}:${this.minutes}`;
  }

  public getDateObject(): Date {
    const dateObj = new Date();

    dateObj.setUTCFullYear(Number(this.year));
    dateObj.setUTCMonth(Number(this.month) - 1);
    dateObj.setUTCDate(Number(this.day));

    return dateObj;
  }
}

export const timeConverter = (timestamp: number, fromJournal?: boolean): string => {
  const a = fromJournal ? new Date(timestamp * 1000) : new Date(timestamp / 1000);
  const year = a.getFullYear();
  const month = (a.getMonth() + 1) < 10 ? `0${a.getMonth() + 1}` : a.getMonth() + 1;
  const day = a.getDate() < 10 ? `0${a.getDate()}` : a.getDate();
  const hour = a.getHours() < 10 ? `0${a.getHours()}` : a.getHours();
  const min = a.getMinutes() < 10 ? `0${a.getMinutes()}` : a.getMinutes();
  const sec = a.getSeconds() < 10 ? `0${a.getSeconds()}` : a.getSeconds();

  const dateTime = `${year}-${month}-${day}T${hour}:${min}:${sec}%2b03:00`;

  return dateTime;
}

export const getDateFortnightAgo = (): Date => {
  const date = new Date();

  date.setUTCHours(0, 0, 0);

  date.setUTCDate(date.getDate() - 14);

  return date;
}

export const getSpecificDate = (daysAgo: number, hoursAgo?: number): Date => {
  const date = new Date();

  date.setUTCHours(hoursAgo ? date.getUTCHours() - hoursAgo : 0, 0, 0, 0);

  if (daysAgo) {
    date.setUTCDate(date.getUTCDate() - daysAgo);
  }

  return date;
}

export const isDateValid = (date: any): boolean => {
  return Object.prototype.toString.call(date) === '[object Date]';
}

export const isWeekDay = (date: Date): boolean => {
  const day = date.getDay();

  return day !== 0 && day !== 6;
}

export function isLeapYear(year: number) {
  if (year % 4 === 0) {
      if (year % 100 !== 0) {
          if (year % 400 === 0) {
              return true;
          }
      }
  }

  return false;
}

export const getMonthLength = (date: Date): number => {
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  switch (month) {
    case 1:
    case 3:
    case 5:
    case 7:
    case 8:
    case 10:
    case 12:
      return 31;
    case 4:
    case 6:
    case 9:
    case 11:
      return 30;
    case 2:
      return isLeapYear(year) ? 29 : 28;
    default:
      return 0;
  }
}

export const getFromDateForRequest = (startDate: Date): string => {
  let fromMonth: string;
  let fromDay: string;

  if (startDate.getMonth() + 1 < 10) {
    fromMonth = `0${startDate.getMonth() + 1}`;
  } else {
    fromMonth = (startDate.getMonth() + 1).toString();
  }

  if (startDate.getDate() < 10) {
    fromDay = `0${startDate.getDate()}`;
  } else {
    fromDay = (startDate.getDate()).toString();
  }

  return `${startDate.getFullYear()}-${fromMonth}-${fromDay}`;
};

export const getToDateForRequest = (endDate: Date): string => {
  let toMonth: string;
  let toDay: string;

  if (endDate.getMonth() + 1 < 10) {
    toMonth = `0${endDate.getMonth() + 1}`;
  } else {
    toMonth = (endDate.getMonth() + 1).toString();
  }

  if (endDate.getDate() < 10) {
    toDay = `0${endDate.getDate()}`;
  } else {
    toDay = (endDate.getDate()).toString();
  }

  return `${endDate.getFullYear()}-${toMonth}-${toDay}`;
};

export function getOneDayAhead(date: Date, returnDateArray?: boolean): any {
  let leapYear = false;
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const dayOfWeek = date.getDay();
  let amountToIncrement: number;

  leapYear = isLeapYear(year);

  switch (dayOfWeek) {
      case 5:
          amountToIncrement = 3;
          break;
      case 6:
          amountToIncrement = 2;
          break;
      default:
          amountToIncrement = 1;
          break;
  }

  const dateObject = {
    year,
    month,
    day
  };

  switch (month) {
      case 1:
      case 3:
      case 5:
      case 7:
      case 8:
      case 10:
        if ((day + amountToIncrement) > 31) {
          dateObject.day = day + amountToIncrement - 31;
          dateObject.month = month + 1;

          return returnDateArray ? [dateObject.year, dateObject.month, dateObject.day] : dateObject;
        }

        dateObject.day = day + amountToIncrement;

        return returnDateArray ? [dateObject.year, dateObject.month, dateObject.day] : dateObject;
      case 4:
      case 6:
      case 9:
      case 11:
        if ((day + amountToIncrement) > 30) {
          dateObject.day = day + amountToIncrement - 30;
          dateObject.month = month + 1;

          return returnDateArray ? [dateObject.year, dateObject.month, dateObject.day] : dateObject;
        }

        dateObject.day = day + amountToIncrement;

        return returnDateArray ? [dateObject.year, dateObject.month, dateObject.day] : dateObject;
      case 2:
        if (leapYear) {
            if ((day + amountToIncrement) > 29) {
                dateObject.day = day + amountToIncrement - 29;
                dateObject.month = month + 1;

                return returnDateArray ? [dateObject.year, dateObject.month, dateObject.day] : dateObject;
            }

            dateObject.day = day + amountToIncrement;

            return returnDateArray ? [dateObject.year, dateObject.month, dateObject.day] : dateObject;
        }

        if ((day + amountToIncrement) > 28) {
          dateObject.day = day + amountToIncrement - 28;
          dateObject.month = month + 1;

          return returnDateArray ? [dateObject.year, dateObject.month, dateObject.day] : dateObject;
        }
          dateObject.day = day + amountToIncrement;

          return returnDateArray ? [dateObject.year, dateObject.month, dateObject.day] : dateObject;
      case 12:
        if ((day + amountToIncrement) > 31) {
          dateObject.day = day + amountToIncrement - 31;
          dateObject.month = 1;
          dateObject.year = year + amountToIncrement;

          return returnDateArray ? [dateObject.year, dateObject.month, dateObject.day] : dateObject;
        }
          dateObject.day = day + amountToIncrement;

          return returnDateArray ? [dateObject.year, dateObject.month, dateObject.day] : dateObject;
      default:
          return returnDateArray ? [dateObject.year, dateObject.month, dateObject.day] : dateObject;
  }
}

export const getMonthByNumber = (number: number): string => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  return months[number - 1];
}

export const getUnixDawnOfTime = (): Date => {
  return new Date(1970, 0, 1);
}

export const getHourAgo = (): Date => {
  const hourAgo = new Date();
  hourAgo.setUTCHours(hourAgo.getUTCHours() - 1);

  return hourAgo;
}

export default PAMMDateTime;