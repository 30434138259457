import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
import { MetaTraderServerSettings, EditMetaTraderServerRequest } from '../../../types/settingsTypes';
import setLoadingStatus from '../setLoadingStatus';

export const FETCH_CONNECTED_MT_SERVERS = 'FETCH_CONNECTED_MT_SERVERS';

export type FetchMtServersSettingsAction = {
  type: string;
  settings: Array<MetaTraderServerSettings>;
};

const fetchMtServersSettings = (settings: Array<MetaTraderServerSettings>): FetchMtServersSettingsAction => {
  return {
    type: FETCH_CONNECTED_MT_SERVERS,
    settings
  };
};

const fetchMtServersSettingsAsync = (api: string) => {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));

    fetch(`${api}/settings/mtServers`, headersBuilder(Method.GET))
    .then((response: Response) => Errors.checkResponseForErrors(response, api))
    .then((addresses: any) => dispatch(fetchMtServersSettings(addresses)))
    .catch((error: Error) => Errors.throwErrorMessage(error))
    .finally(() => dispatch(setLoadingStatus(false)));
  }
};

export const editServerSettingsAsync = (api: string, request: EditMetaTraderServerRequest) => {
  return (): void => {
    fetch(`${api}/settings/mtServers`, headersBuilder(Method.POST, request))
    .then((response: Response) => Errors.checkResponseForErrors(response, api))
    .then(() => {
      toast.success('Connected Meta Trader servers settings edited successfully!', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    })
    .catch((error: Error) => Errors.throwErrorMessage(error));
  }
};

export default fetchMtServersSettingsAsync;
