export const SET_LOADING_STATUS = 'SET_LOADING_STATUS ';

export type SetLoadingStatusAction = {
  type: string;
  status: boolean;
}

export const setLoadingStatus = (status: boolean): SetLoadingStatusAction => {
  return {
    type: SET_LOADING_STATUS,
    status
  }
};

export default setLoadingStatus;
