import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
import setLoadingStatus from '../../actions/setLoadingStatus';

export const FETCH_STATEMENT_PERIODS_SETTINGS = 'FETCH_STATEMENT_PERIODS_SETTINGS';
export const FETCH_REPORT_PERIODS_SETTINGS = 'FETCH_REPORT_PERIODS_SETTINGS';
export const FETCH_PAYMENT_PERIODS_SETTINGS = 'FETCH_PAYMENT_PERIODS_SETTINGS';

export type FetchPeriodsSettings = {
  type: string;
  settings: string[];
};

const getActionConst = (actionName: string): string => {
  switch (actionName) {
    case 'statementPeriods':
      return FETCH_STATEMENT_PERIODS_SETTINGS;
    case 'reportPeriods':
      return FETCH_REPORT_PERIODS_SETTINGS;
    case 'paymentPeriods':
      return FETCH_PAYMENT_PERIODS_SETTINGS;
    default:
      return 'unknown';
  }
};

const fetchPeriodsSettings = (settings: string[], periodsType: string): FetchPeriodsSettings => {
  return {
    type: getActionConst(periodsType),
    settings
  };
};

const fetchPeriodsSettingsAsync = (api: string, periodsType: string) => {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));
    
    fetch(`${api}/settings/${periodsType}`, headersBuilder(Method.GET))
    .then((response: Response) => Errors.checkResponseForErrors(response, api))
    .then((settings: any) => dispatch(fetchPeriodsSettings(settings[Object.keys(settings)[0]], periodsType)))
    .catch((error: Error) => Errors.throwErrorMessage(error))
    .finally(() => dispatch(setLoadingStatus(false)));
  }
};

export const editPeriodsSettingsAsync = (api: string, request: any, periodsType: string) => {
  return (dispatch: Dispatch): void => {
    fetch(`${api}/settings/${periodsType}s`, headersBuilder(Method.POST, request))
    .then((response: Response) => Errors.checkResponseForErrors(response, api))
    .then((settings: any) => {
      dispatch(fetchPeriodsSettings(settings.Periods, periodsType));
      toast.success('Periods settings edited successfully!', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    })
    .catch((error: Error) => Errors.throwErrorMessage(error));
  }
};

export default fetchPeriodsSettingsAsync;
