/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Icons from '../../icons/icons';
import { setLanguagesMenuStatus, setOpsMenuStatus } from '../../redux/actions/setMobileMenusStatus';
import { PAMMState } from '../../redux/reducers/rootReducer';
import { AccountType } from '../../types/authTypes';
import LocalStorageUtils from '../../utils/local-storage';

const OperationsMenuMobile: React.FC<any> = ({
  menuHeight = 0,
  onSwipe,
  onCancel,
  location,
  currentLang
}): JSX.Element => {
  const dispatch = useDispatch();

  const languages: Record<string, string> = useSelector((state: PAMMState) => state.configReducer.languages);
  const customColorsGenUser = useSelector((state: PAMMState) => state.configReducer.customColorsGeneralUser);
  const userJSON: string | null = LocalStorageUtils.getValueFromLocalStorage('user');

  let user: any;

  if (typeof userJSON === 'string') {
    user = JSON.parse(userJSON);
  }

  React.useEffect(() => {
    dispatch(setOpsMenuStatus(false));
  }, [dispatch, location]);

  const handleShowLanguagesMenu = (): void => {
    dispatch(setOpsMenuStatus(false));
    dispatch(setLanguagesMenuStatus(true));
  };

  const style = {
    width: '100%',
    height: menuHeight,
    position: 'fixed',
    zIndex: 9999,
    bottom: '-5rem',
    backgroundColor: 'white',
    transition: '0.5s',
    padding: '1.5rem',
    fontSize: '1.3rem'
  } as React.CSSProperties;

  const iconStyle = {
    borderRadius: '50%',
    backgroundColor: '#E9E9E9',
    width: '2.5rem',
    height: '2.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  } as React.CSSProperties;

  return (
    <div
      id="operations-menu"
      style={style}
      onPointerMove={(event) => onSwipe(event)}
      onPointerCancel={() => onCancel('operationsMenu')}
    >
      {/* INVESTOR: Deposit or Deposit/Withdrawal */}
      {user?.AccountType === AccountType.Investor && user?.AccountInfo?.AccountOptions?.InvestorWithdrawButton === 'Show'
      ? (
        <Link to={`/change-balance/${user?.AccountInfo?.Id}`}>
          <div className="row mb-3 align-items-center">
            <span className="mx-3" style={iconStyle}>
              {Icons.ArrowsAltH(customColorsGenUser.dark, 'sm')}
            </span>
            <span className="mb-1">
              <FormattedMessage id="operations.depositWithdrawal" />
            </span>
          </div>
        </Link>
      ) : null}

      {/* MASTER: Deposit/Withdrawal */}
      {/* It should use the Master's OwnFunds ID, instead of the Master's own ID */}
      {user?.AccountType === AccountType.Master && user?.AccountInfo?.AccountOptions?.ShowMmOwnFunds === 'Show'
      ? (
        <Link to={`/change-balance/${user?.AccountInfo?.OwnFunds?.Id}`}>
          <div className="row mb-3 align-items-center">
            <span className="mx-3" style={iconStyle}>
              {Icons.ArrowsAltH(customColorsGenUser.dark, 'sm')}
            </span>
            <span className="mb-1">
              <FormattedMessage id="operations.depositWithdrawal" />
            </span>
          </div>
        </Link>
      ) : null}

      {/* INVESTOR: Credit In/Out */}
      {user?.AccountType === AccountType.Investor && user?.AccountInfo?.AccountOptions?.InvestorCreditButton === 'Show'
      ? (
        <Link to={`/change-credit/${user?.Id}`}>
          <div className="row mb-5 align-items-center">
            <span className="mx-3" style={iconStyle}>
              {Icons.ArrowsAltH(customColorsGenUser.dark, 'sm')}
            </span>
            <span className="mb-1">
              <FormattedMessage id="operations.creditInOut" />
            </span>
          </div>
        </Link>
      ) : null}
      {/* An empty space, if user IS Investor, but can't see their Credit button */}
      {user?.AccountType === AccountType.Investor && user?.AccountInfo?.AccountOptions?.InvestorCreditButton === 'Hide'
      ? (
        <div className="mb-5">
          {/* space */}
        </div>
      ) : null}

      {/* MASTER: Credit In/Out */}
      {user?.AccountType === AccountType.Master && user?.AccountInfo?.AccountOptions?.ShowMmOwnFunds === 'Show'
      ? (
        <Link to={`/change-credit/${user?.AccountInfo?.OwnFunds?.Id}`}>
          <div className="row mb-5 align-items-center">
            <span className="mx-3" style={iconStyle}>
              {Icons.ArrowsAltH(customColorsGenUser.dark, 'sm')}
            </span>
            <span className="mb-1">
              <FormattedMessage id="operations.creditInOut" />
            </span>
          </div>
        </Link>
      ) : null}
      {/* An empty space, if user IS Master, but can't see their OwnFunds account */}
      {user?.AccountType === AccountType.Master && user?.AccountInfo?.AccountOptions?.ShowMmOwnFunds === 'Hide'
      ? (
        <div className="mb-5">
          {/* space */}
        </div>
      ) : null}

      {/* MASTER and INVESTOR: Orders Commissions */}
      {user?.AccountInfo?.AccountOptions?.OrdersCommissions === 'Show'
      ? (
        <Link to={`/commissions/${user?.AccountInfo?.Id}`}>
          <div className="row mb-5 align-items-center">
            <span className="mx-3" style={iconStyle}>
              {Icons.DollarSignIcon(customColorsGenUser.dark, 'sm')}
            </span>
            <span className="mb-1">
              <FormattedMessage id="operations.commissions" />
            </span>
          </div>
        </Link>
      ) : (
        <div className="mb-5">
          {/* space */}
        </div>
      )}

      {/* MASTER: Connected Investors */}
      {user?.AccountType === AccountType.Master && user?.AccountInfo?.AccountOptions?.MmConnectedInvestors === 'Show'
      ? (
        <Link to={`/connected-investors/${user?.AccountInfo?.Id}`}>
          <div className="row mb-3 align-items-center">
            <span className="mx-3" style={iconStyle}>
              {Icons.UsersIcon(customColorsGenUser.dark, 'sm')}
            </span>
            <span className="mb-1">
              <FormattedMessage id="operations.connectedInvestors" />
            </span>
          </div>
        </Link>
      ) : (
        <div className="mb-5">
          {/* space */}
        </div>
      )}


      {/* History: Always show for INVESTOR and MASTER */}
      <Link to={`/history/${user?.AccountInfo?.Id}`}>
        <div className="row mb-3 align-items-center">
          <span className="mx-3" style={iconStyle}>
            {Icons.HistoryIcon(customColorsGenUser.dark, 'sm')}
          </span>
          <span className="mb-1">
            <FormattedMessage id="operations.history" />
          </span>
        </div>
      </Link>

      {/* Statements: Condition for INVESTOR, but always show for MASTER */}
      {user?.AccountInfo?.AccountOptions?.InvestorStatementButton === 'Show' || user?.AccountType === AccountType.Master
      ? (
        <Link to={`/download-statement/${user?.AccountInfo?.Id}`}>
          <div className="row mb-5 align-items-center">
            <span className="mx-3" style={iconStyle}>
              {Icons.FileAltIcon(customColorsGenUser.dark, 'sm')}
            </span>
            <span className="mb-1">
              <FormattedMessage id="operations.statements" />
            </span>
          </div>
        </Link>
      ) : (
        <div className="mb-5">
          {/* space */}
        </div>
      )}

      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        className="row mb-3 align-items-center"
        onClick={handleShowLanguagesMenu}
      >
        <span className="mx-3" style={iconStyle}>
          {Icons.LanguageIcon(customColorsGenUser.dark, 'sm')}
        </span>
        <span className="mb-1">
          {languages[currentLang] || languages.en}
        </span>
      </div>
    </div>
  );
};

export default withRouter(OperationsMenuMobile);
