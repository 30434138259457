export const getOperationsMenuHeight = (userType: string, accountOptions: any): any => {
  const regularButton = 59.5; // Height in pixels WITH bottom margin
  const separationButton = 91.5; // Height in pixels WITHOUT bottom margin

  let height = 0;

  if (userType === 'investor') {
    // Deposit/Withdrawal
    if (accountOptions.InvestorWithdrawButton === 'Show' && accountOptions.InvestorCreditButton === 'Show') {
      height += regularButton;
    }
    if (accountOptions.InvestorWithdrawButton === 'Show' && accountOptions.InvestorCreditButton === 'Hide') {
      height += separationButton;
    }

    if (accountOptions.InvestorCreditButton === 'Show' && accountOptions.InvestorWithdrawButton === 'Show') {
      height += regularButton;
    }
    if (accountOptions.InvestorCreditButton === 'Show' && accountOptions.InvestorWithdrawButton === 'Hide') {
      height += separationButton;
    }

    // Commissions button
    if (accountOptions.OrdersCommissions === 'Show') {
      height += separationButton;
    }

    // History button
    height += regularButton;

    // Statements button
    if (accountOptions.InvestorStatementButton === 'Show') {
      height += separationButton;
    } else {
      // History becomes a separationButton
      height -= regularButton;
      height += separationButton;
    }

    // Language button
    height += regularButton;
  }

  if (userType === 'master') {
    // Deposit button
    height += regularButton;

    // Withdrawal button
    height += separationButton;

    // Commissions button
    if (accountOptions.OrdersCommissions === 'Show') {
      height += separationButton;
    }

    // Connected Investors button
    if (accountOptions.MmConnectedInvestors === 'Show') {
      height += regularButton;
    }

    // History button
    height += regularButton;

    // Statements button
    height += separationButton;

    // Language button
    height += regularButton;
  }

  // Padding top and bottom
  height += 110;

  return height;
};